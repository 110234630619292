var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepFirstContent.title1"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepFirstContent.explanation"
          ),
          records: _vm.startDateRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepFirstContent.title2"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepFirstContent.explanation"
          ),
          records: _vm.baseRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepFirstContent.title3"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepFirstContent.explanation"
          ),
          records: _vm.insuredRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: null,
          "content-page-name": _vm.contentPageName,
          explanation: null,
          noticeText: _vm.$t(
            "tableItems.reviewContract.stepFirstContent.noticeText"
          ),
          records: _vm.insuredTitleRecords,
          isInsuredTitleRecords: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }